export const Propertiesdata = [
  {
    id: 1,
    src: "assets/building/house1.jpg",
    title: "aaa DC2",
    city: "Tangier",
    address: "Tangier Sidi Driss",
    type: "Building",
    description: "Total Available Area: 3,180 - 8559 SQM",
    link: "prologis.com",
    price: 56,
    size: 1050,
  },
  {
    id: 2,
    src: "assets/building/house1.jpg",
    title: "COSLADA DC2",
    city: "Tangier",
    address: "Tangier BNI MAKADA",
    type: "Building",
    description: "Total Available Area: 3,180 - 8559 SQM",
    link: "prologis.com",
    price: 56,
    size: 200,
  },
  {
    id: 3,
    src: "assets/building/house1.jpg",
    title: "COSLADA DC2",
    city: "Tangier",
    address: "Tangier MEDINA",
    type: "Building",
    description: "Total Available Area: 3,180 - 8559 SQM",
    link: "prologis.com",
    price: 56,
    size: 50,
  },
  {
    id: 4,
    src: "assets/building/house1.jpg",
    brand: "Audi",
    title: "Audi RSQ8",
    city: "Casa blanca",
    address: "Tangier Sidi Driss",
    type: "Cars",
    description: "lorem upsum description",
    link: "prologis.com",
    price: 230,
  },
  {
    id: 5,
    src: "assets/building/house1.jpg",
    brand: "MERCEDESS",
    title: "MERCEDESS BENZ",
    city: "Tangier",
    address: "Tangier BNI MAKADA",
    type: "Cars",
    description: "lorem upsum description",
    link: "prologis.com",
    price: 120,
  },
  {
    id: 6,
    src: "assets/building/house1.jpg",
    brand: "Reanult",
    title: "Reanult Clio",
    city: "Tangier",
    address: "Tangier MEDINA",
    type: "Cars",
    description: "lorem upsum description",
    link: "prologis.com",
    price: 16,
  },
  {
    id: 7,
    src: "assets/building/house1.jpg",
    brand: "Reanult",
    title: "Reanult Clio",
    city: "Tangier",
    address: "Tangier MEDINA",
    type: "Cars",
    description: "This Shit is Ass N*gga",
    link: "prologis.com",
    price: 23,
  },
];
